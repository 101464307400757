
  import {shuffleArray} from '@/helper/ArrayHelper';
  import {prepareImageUrl} from '@/helper/ImageHelper';
  import WikiCard from '@/components/elements/WikiCard';

  export default {
    components: {
      WikiCard,
    },
    props: {
      numberOfCards: {
        type: Number,
        required: true,
        default: 2,
      },
      columnsNumber: {
        type: Number,
        required: true,
        default: 6,
      },
      fillHeight: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        teaseredWikis: [],
        loading: true,
      };
    },
    async fetch() {
      // Query for teasered wikis
      const teaseredWikis = await this.$cachedStrapi.find('wikis', {
        scope_contains: process.env.SCOPE,
        teasered: true,
      });

      // Reduce and shuffle teasered wikis
      this.teaseredWikis = shuffleArray(teaseredWikis)
        .slice(0, this.numberOfCards)
        .sort((a, b) => (a.title > b.title ? 1 : -1));
      this.loading = false;
    },
    mounted() {
      if (this.teaseredWikis && this.teaseredWikis.length > 0) {
        this.loading = false;
      }
    },
    methods: {
      imageUrl(wiki) {
        return wiki.cover[0]
          ? prepareImageUrl(wiki.cover[0].url)
          : require(process.env.NUXT_ENV_FALLBACK_CARD_IMAGE);
      },
    },
  };
